import React, { useEffect, useState } from 'react';
import { useWallet } from '@solana/wallet-adapter-react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBCardHeader,
  MDBTypography,
  MDBBtn,
  MDBInput,
  MDBIcon
} from 'mdb-react-ui-kit';
import './ProfilePage.css'; // Assume you're adding a custom CSS file

require('dotenv').config();
const apiUrl = process.env.REACT_APP_API_URL || '';

const ProfilePage = ({ setShowProfile }) => {
  const { publicKey } = useWallet();
  const [profile, setProfile] = useState({});
  const [additionalWallets, setAdditionalWallets] = useState([]);
  const [newWallet, setNewWallet] = useState("");
  const location = useLocation();

  const fetchProfile = async () => {
    try {
      if (publicKey) {
        const response = await axios.get(`${apiUrl}/api/user/${publicKey.toString()}`, { withCredentials: true });
        setProfile(response.data);
        setAdditionalWallets(response.data.additionalWallets || []);
      }
    } catch (error) {
      console.error('Error fetching user profile:', error);
    }
  };

  useEffect(() => {
    fetchProfile();
  }, [publicKey]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    if (params.get('twitterLinked')) {
      fetchProfile();
    }
  }, [location.search]);

  const handleTwitterLogin = () => {
    window.location.href = `${apiUrl}/auth/twitter?publicKey=${publicKey}`;
  };

  const handleUnlinkTwitter = async () => {
    try {
      if (publicKey) {
        await axios.post(`${apiUrl}/api/unlink-twitter`, { publicKey: publicKey.toString() }, { withCredentials: true });
        fetchProfile();
      }
    } catch (error) {
      console.error('Error unlinking Twitter account:', error);
    }
  };

  const handleAddWallet = async () => {
    if (newWallet && !additionalWallets.includes(newWallet)) {
      try {
        const response = await axios.post(`${apiUrl}/api/user/add-wallet`, {
          publicKey: publicKey.toString(),
          newWallet: newWallet
        }, { withCredentials: true });

        setAdditionalWallets(response.data.additionalWallets);
        setNewWallet("");
      } catch (error) {
        console.error('Error adding new wallet:', error);
      }
    }
  };

  const deleteWallet = async (walletToDelete) => {
    try {
      const response = await axios.post(`${apiUrl}/api/delete-wallet`, {
        publicKey: publicKey.toString(),
        walletToDelete
      }, { withCredentials: true });

      if (response.data.success) {
        setAdditionalWallets(response.data.additionalWallets);
      }
    } catch (error) {
      console.error('Error deleting wallet:', error);
    }
  };

  return (
    <MDBContainer className="profile-container">
      <MDBRow>
        <MDBCol md="6" className="offset-md-3">
          <MDBCard className="shadow-lg">
            <MDBCardHeader className="text-center text-white">
              <MDBTypography tag="h2">Profile</MDBTypography>
            </MDBCardHeader>
            <MDBCardBody>
              {profile.socialsLinked ? (
                <>
                  <div className="text-center text-white">
                    <img
                      src={profile.profilePicture || "https://via.placeholder.com/150"}
                      alt="Profile"
                      className="rounded-circle profile-img mb-3"
                      width="40"
                    />
                    <h4>{profile.username || 'No username'}</h4>
                    <p>Socials verified</p>
                  </div>
                  <div className="text-center text-white">
                    <MDBBtn color="danger" onClick={handleUnlinkTwitter} className="mt-3 unlinkBtn">
                      <MDBIcon fab icon="twitter" className="me-2" /> Unlink Twitter
                    </MDBBtn>
                  </div>
                </>
              ) : (
                <div className="text-center">
                  <MDBBtn color="primary" onClick={handleTwitterLogin} className="mt-3 unlinkBtn">
                    <MDBIcon fab icon="twitter" className="me-2" /> Link Twitter
                  </MDBBtn>
                </div>
              )}

              <div className="mt-4">
                <h5 className="section-header text-white">Your Wallets</h5>
                <ul className="wallet-list text-white">
                  <li>{profile.publicKey}</li>
                  {additionalWallets.map((wallet) => (
                    <li key={wallet}>
                      {wallet}
                      <MDBBtn
                        color="danger"
                        size="sm"
                        className="ms-3 deleteBtn"
                        onClick={() => deleteWallet(wallet)}
                      >
                        Delete
                      </MDBBtn>
                    </li>
                  ))}
                </ul>
                <MDBInput
                  type="text"
                  value={newWallet}
                  onChange={(e) => setNewWallet(e.target.value)}
                  label="Add another wallet"
                />
                <MDBBtn onClick={handleAddWallet} className="mt-3 addWalletBtn">
                  <MDBIcon icon="plus-circle" className="me-2" /> Add Wallet
                </MDBBtn>
              </div>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
};

export default ProfilePage;
