import React, { useEffect, useState, useMemo } from "react";
import { MDBBtn, MDBInput } from "mdb-react-ui-kit";
import Particles, { initParticlesEngine } from "@tsparticles/react";
import { loadSlim } from "@tsparticles/slim";
import Cookies from "js-cookie";
import Helmet from "react-helmet"
import { ReactTyped } from "react-typed";
import xIcon from '../assets/xIcon.webp';
import "./PasswordProtect.css";

const apiUrl =
  process.env.REACT_APP_API_URL ||
  process.env.REACT_APP_API_URL_DEV ||
  process.env.REACT_APP_API_URL_PREVIEW ||
  "";

const ParticleBackground = React.memo(() => {
  const options = useMemo(
    () => ({
      background: { color: { value: "transparent" } },
      fpsLimit: 120,
      interactivity: {
        events: { onClick: { enable: true, mode: "push" }, onHover: { enable: true, mode: "repulse" } },
        modes: { push: { quantity: 4 }, repulse: { distance: 200, duration: 0.4 } }
      },
      particles: {
        color: { value: "#ffffff" },
        links: { color: "#ffffff", distance: 150, enable: true, opacity: 0.5, width: 1 },
        move: { enable: true, speed: 2, outModes: { default: "bounce" } },
        number: { density: { enable: true }, value: 80 },
        opacity: { value: 0.5 },
        shape: { type: "circle" },
        size: { value: { min: 1, max: 5 } }
      },
      detectRetina: true,
    }),
    []
  );


  useEffect(() => {
    initParticlesEngine(async (engine) => {
      await loadSlim(engine);
    });
  }, []);

  return <Particles id="tsparticles" options={options} />;
});

const PasswordProtect = ({ onPasswordSubmit }) => {
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [showForm, setShowForm] = useState(false);
  const [showBanner, setShowBanner] = useState(false);

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`${apiUrl}/api/authenticate`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ password }),
        credentials: "include",
      });
      const data = await response.json();
      if (data.authenticated) {
        Cookies.set("authenticated", "true");
        onPasswordSubmit();
      } else {
        setError("Incorrect password. Please try again.");
      }
    } catch (error) {
      setError("This is not what I want to hear.");
    }
  };

  return (

    <div className={`password-protect-container ${showForm ? "show-form" : ""}`}>
    {showBanner && (
           <div
             style={{
               backgroundColor: "#ffcc00",
               color: "#333",
               textAlign: "center",
               padding: "10px 20px",
               position: "sticky",
               top: 0,
               width: "100%",
               zIndex: 9999,
               fontSize: "16px",
               fontWeight: "bold",
               display: "flex",
               justifyContent: "space-between",
               alignItems: "center",
               boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
               position: "absolute",
               top: 0,
               left: 0,
             }}
           >
             <p style={{ margin: 0, padding: 0, flexGrow: 1 }}>
               Our waitlist is currently experiencing heavy traffic and might have
               overwhelmed our third-party vendor's servers. Please try again in a
               few hours if it's not working.
             </p>
             <button
               style={{
                 background: "none",
                 border: "none",
                 fontSize: "18px",
                 cursor: "pointer",
                 color: "#333",
               }}
               onClick={() => setShowBanner(false)}
             >
               &#x2715;
             </button>
           </div>
         )}
      {/* Particles background is now isolated and won't re-render */}
      <ParticleBackground />
      <div className="waitlist">
        <div id="getWaitlistContainer" data-waitlist_id="21226" data-widget_type="WIDGET_1"></div>
        <Helmet>
          <link rel="stylesheet" type="text/css" href="https://prod-waitlist-widget.s3.us-east-2.amazonaws.com/getwaitlist.min.css"/>
          <script src="https://prod-waitlist-widget.s3.us-east-2.amazonaws.com/getwaitlist.min.js"></script>
        </Helmet>
      </div>
      <div className="twitterIcon">
        <a href="https://x.com/dusted_app" target="_blank">
          <img src={xIcon} alt="twitter" style={{ width: '32px', height: '32px' }} />
        </a>
      </div>
    </div>
  );
};

export default PasswordProtect;

{/*<div className="password-protect-box">
        <ReactTyped
          strings={["Knock, and it shall be opened unto you."]}
          typeSpeed={50}
          onComplete={() => setShowForm(true)} // Show form after typing animation completes
          className="typing-text"
        />
        <div className="form-container">
          <form onSubmit={handleSubmit}>
            <MDBInput
              type="password"
              value={password}
              onChange={handlePasswordChange}
              placeholder="Enter password"
              className="password-input"
            />
            <MDBBtn type="submit" className="password-submit-btn mt-3">
              Knock
            </MDBBtn>
            {error && <p className="error-message">{error}</p>}
          </form>
        </div>
      </div> */}