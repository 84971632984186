import React, { useEffect, useState, useRef, useMemo } from 'react';
import { WalletMultiButton } from '@solana/wallet-adapter-react-ui';
import { useWallet } from '@solana/wallet-adapter-react';
import logo from '../assets/logo.png';
import './Navbar.css';
import axios from 'axios';
import {
  MDBNavbar,
  MDBContainer,
  MDBNavbarBrand,
  MDBNavbarToggler,
  MDBIcon,
  MDBCollapse,
  MDBNavbarNav,
  MDBNavbarItem,
  MDBNavbarLink,
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem,
  MDBBadge
} from 'mdb-react-ui-kit';
import { Link } from 'react-router-dom';
const apiUrl = process.env.REACT_APP_API_URL || process.env.REACT_APP_API_URL_DEV || process.env.REACT_APP_API_URL_PREVIEW || '';
const Navbar = ({ setShowProfile, socket, setSelectedToken }) => {
  const { publicKey } = useWallet();
  const [profile, setProfile] = useState({});
  const userId = publicKey ? publicKey.toBase58() : null;
  const [showNav, setShowNav] = React.useState(false);
  const [newToken, setNewToken] = useState(null);
  const [showBell, setShowBell] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const notificationRef = useRef(null);
  useEffect(() => {
    if (!socket) return; // If no socket, do nothing

    socket.on('newToken', (data) => {
      console.log('New token received:', data);
      setNewToken(data);  // Update state with new token data
      setShowBell(true);  // Show the bell icon
    });

    // Cleanup event listener on component unmount
    return () => {
      socket.off('newToken');

    };
  }, [socket]);

  const handleBellClick = () => {
    if (newToken) {
      setShowNotification(true); // Show the popup with token info
    }
    setShowBell(false); // Hide the bell icon after acknowledging the new token
  };

  const handleJoin = async () => {
    if (newToken) {
      // Fetch token metadata
      let tokenMeta;
      try {
        const apiResponse = await axios.get(`${apiUrl}/api/token/${newToken.tokenMint}`);
        tokenMeta = apiResponse.data;
      } catch (error) {
        console.error(`Error fetching metadata for token ${newToken.tokenMint}:`, error);
        tokenMeta = {
          mint: newToken.tokenMint,
          name: 'Unknown Token',
          symbol: newToken.symbol || 'N/A',
          usdPrice: 'N/A',
          marketCap: 'N/A',
          change24: 'N/A',
          volume24: 'N/A',
          rawMarketCap: 0,
          links: {},
          logo: 'N/A',
        };
      }

      const newTokenData = {
        mint: newToken.tokenMint,
        amount: 0,
        ...tokenMeta,
      };

      setSelectedToken(newTokenData);
    }
    setShowNotification(false);
    setNewToken(null);
  };



  const fetchProfile = async () => {
    try {
      if (publicKey) {
        const response = await axios.get(`${apiUrl}/api/user/${publicKey.toString()}`, { withCredentials: true });
        setProfile(response.data);
      }
    } catch (error) {
      console.error('Error fetching user profile:', error);
    }
  };

  useEffect(() => {
    fetchProfile();
  }, [publicKey]);

  const handleProfileClick = () => {
    setShowProfile(true);
  };

  return (
    <MDBNavbar expand='lg' light>
      <MDBContainer fluid>
        <MDBNavbarToggler
          aria-controls='navbarSupportedContent'
          aria-expanded='false'
          style={{color: 'white'}}
          aria-label='Toggle navigation'
          onClick={() => setShowNav(!showNav)}
        >
          <MDBIcon icon='bars' fas />
        </MDBNavbarToggler>


          <MDBNavbarNav className='me-auto mb-2 mb-lg-0'>
          <MDBNavbarBrand href='/'>
            <img
              src={logo}
              height='24'
              alt='MDB Logo'
              loading='lazy'
              className='logoNav'
            />
          </MDBNavbarBrand>
            <MDBNavbarItem>
              <MDBNavbarLink className="nav-link text active" active as={Link} to='/'>Chat
              </MDBNavbarLink>
            </MDBNavbarItem>
            <MDBNavbarItem>
              <MDBNavbarLink className="nav-link text" as={Link} to='/team'>Trending
              </MDBNavbarLink>
            </MDBNavbarItem>
            <MDBNavbarItem>
              <MDBNavbarLink className="nav-link text" as={Link} to='/projects'>Rewards
              </MDBNavbarLink>
            </MDBNavbarItem>
          </MDBNavbarNav>


        <div className='d-flex align-items-center right-nav-menu'>
          <div>
            <a className='text-reset' href='#'>
              <MDBIcon far icon='envelope' />
            </a>
          </div>

          <div className="bellIcon">
            <div style={{ cursor: 'pointer', position: 'relative' }} onClick={() => setShowNotification(!showNotification)}>
              {/* Display bell icon */}
              <MDBIcon fas icon='bell' size='lg' className={showBell ? 'text-white' : ''} onClick={handleBellClick} />
              {showBell && (
                    <MDBBadge color='danger' notification pill>
                      1
                    </MDBBadge>
              )}
              {showNotification && (
                <div
                  ref={notificationRef}
                  className="tokenNotification"
                >
                  {newToken ? (
                    <>
                      <p onClick={handleJoin} style={{ margin: 0 }}>New token detected: ${newToken.symbol || newToken.tokenMint}</p>
                    </>
                  ) : (
                    <p style={{ margin: 0 }}>No new notifications</p>
                  )}
                </div>
              )}
            </div>

          </div>


          <MDBDropdown>
            <MDBDropdownToggle tag='a' className='nav-link hidden-arrow d-flex align-items-center pfp' href='#'>
              <img
                src={profile.profilePicture || 'https://via.placeholder.com/150'}
                className='rounded-circle pfp'
                height='25'
                alt='Profile'
                loading='lazy'
              />
            </MDBDropdownToggle>
            <MDBDropdownMenu>
              <MDBDropdownItem link onClick={handleProfileClick}>My profile</MDBDropdownItem>
              <MDBDropdownItem link>Settings</MDBDropdownItem>
              <MDBDropdownItem link>Logout</MDBDropdownItem>
            </MDBDropdownMenu>
          </MDBDropdown>

          <WalletMultiButton className="btn btn-primary ms-3" />
        </div>
      </MDBContainer>
    </MDBNavbar>
  );
};

export default Navbar;
