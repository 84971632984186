import { ConnectionProvider, WalletProvider, useWallet } from '@solana/wallet-adapter-react';
import React, { useMemo, useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { WalletModalProvider, WalletMultiButton } from '@solana/wallet-adapter-react-ui';
import { PhantomWalletAdapter, SolflareWalletAdapter } from '@solana/wallet-adapter-wallets';
import WalletConnect from './components/WalletConnect';
import ChatRoom from './components/ChatRoom';
import Navbar from './components/Navbar';
import ProfilePage from './components/ProfilePage';
import PasswordProtect from './components/PasswordProtect';
import Waitlist from './components/WaitlistNoPw';
import '@fortawesome/fontawesome-free/css/all.min.css';
import '@solana/wallet-adapter-react-ui/styles.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'mdb-react-ui-kit/dist/css/mdb.min.css';
import CollapseIcon from './assets/collapse.png';
import './App.css';
import Madness from './components/Madness';
import io from 'socket.io-client';
import { MDBCol, MDBBtn } from 'mdb-react-ui-kit';
import './components/AuthStyles.css';
import Helmet from "react-helmet"
import axios from 'axios';
import Cookies from 'js-cookie';
require('dotenv').config();
const apiUrl = process.env.REACT_APP_API_URL || process.env.REACT_APP_API_URL_DEV || process.env.REACT_APP_API_URL_PREVIEW || '';



const AppContent = ({
  handlePasswordSubmit,
  isAuthenticated,
  setSelectedToken,
  selectedToken,
  showProfile,
  setShowProfile,
  isWrapped,
  setIsWrapped
}) => {
  const { connected, publicKey } = useWallet();
  const userId = publicKey ? publicKey.toBase58() : null;
  const socketUrl = process.env.REACT_APP_SOCKET_URL || 'http://localhost:5000';
  const socket = useMemo(() => {
    if (userId) {
      return io(socketUrl, {
        reconnection: true,
        reconnectionAttempts: 5,
        reconnectionDelay: 1000,
        query: { userId }
      });
    }
    return null;
  }, [socketUrl, userId]);

  // Check if the user is authenticated first
  if (!isAuthenticated) {
    return <PasswordProtect onPasswordSubmit={handlePasswordSubmit} />;
  }


  // Once authenticated, check if the wallet is connected
  if (!connected) {
    return (
      <div className="auth-container">
        <div className="auth-box">
          <h2>You need to connect your wallet to start</h2>
          <WalletMultiButton className="wallet-connect-btn" />
        </div>
      </div>
    );
  }

  return (
    <>
      <Navbar setShowProfile={setShowProfile} socket={socket} setSelectedToken={setSelectedToken} />
      <div className="container-fluid">
        <div className="row justify-content-between">
          {showProfile ? (
            <ProfilePage setShowProfile={setShowProfile} />
          ) : (
            <>
              <MDBCol md="3" sm="2" size="2" className={`mb-4 mb-md-0 walletConnectCol ${isWrapped ? 'wrapped' : ''}`}>
                <WalletConnect socket={socket}  isWrapped={isWrapped} setSelectedToken={setSelectedToken} />
              </MDBCol>
              <MDBCol size={isWrapped ? "10" : "9"} className="chatRoomCol position-relative">
                <MDBBtn color="light" className="toggle-btn collapseBtn d-none" onClick={() => setIsWrapped(!isWrapped)}>
                  <img src={CollapseIcon} alt="Profile" className="collapseIcon" />
                </MDBBtn>
                {selectedToken && <ChatRoom selectedToken={selectedToken} socket={socket} />}
              </MDBCol>
            </>
          )}
        </div>
      </div>
    </>
  );
};

const App = () => {

  const wallets = useMemo(() => [
    new PhantomWalletAdapter(),
    new SolflareWalletAdapter(),
  ], []);

  const [selectedToken, setSelectedToken] = useState(null);
  const [showProfile, setShowProfile] = useState(false);
  const [isWrapped, setIsWrapped] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const handleSetSelectedToken = (token) => {
    setSelectedToken(token);
    setShowProfile(false);
  };

  const handlePasswordSubmit = () => {
    setIsAuthenticated(true);
  };

  useEffect(() => {
    if (window.location.pathname === '/demo') {
      const checkAuth = async () => {
        try {
          const response = await axios.get(`${apiUrl}/api/check-auth`, { withCredentials: true });
          if (response.data.authenticated) {
            setIsAuthenticated(true);
          }
        } catch (error) {
          setIsAuthenticated(false);
        }
      };

      checkAuth();
    }
  }, []);

  return (
    <ConnectionProvider endpoint="https://morning-muddy-breeze.solana-mainnet.quiknode.pro/26317e4b31e6e7bbd6966605ced0a07e2d935729/">
      <WalletProvider wallets={wallets} autoConnect>
        <WalletModalProvider>
          <div className="gradient-custom">
          <Router>
            <Routes>
              {/* Waitlist page on / */}
              <Route exact path="/" element={<Waitlist />} />

              <Route exact path="/madness" element={<Madness />} />

              {/* Main application under /demo */}
              <Route
                path="/demo"
                element={
                  <AppContent
                    handlePasswordSubmit={handlePasswordSubmit}
                    isAuthenticated={isAuthenticated}
                    setSelectedToken={handleSetSelectedToken}
                    selectedToken={selectedToken}
                    showProfile={showProfile}
                    setShowProfile={setShowProfile}
                    isWrapped={isWrapped}
                    setIsWrapped={setIsWrapped}
                  />
                }
              />
            </Routes>
          </Router>

          </div>
        </WalletModalProvider>
      </WalletProvider>
    </ConnectionProvider>
  );
};

export default App;