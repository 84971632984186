import React from 'react';
import Picker from 'emoji-picker-react';

const EmojiSelector = ({ onEmojiSelect }) => {
  const onEmojiClick = (event, emojiObject) => {
    onEmojiSelect(event);
  };

  return (
    <div>
      <Picker onEmojiClick={onEmojiClick} />
    </div>
  );
};

export default EmojiSelector;
