import React, { useEffect, useState, useCallback, useMemo, useRef } from 'react';
import { useWallet } from '@solana/wallet-adapter-react';
import { WalletMultiButton } from '@solana/wallet-adapter-react-ui';
import { Connection, PublicKey } from '@solana/web3.js';
import axios from 'axios';
import { formatDistanceToNow } from 'date-fns';
import { MDBCol, MDBCard, MDBCardBody, MDBTypography, MDBBtn} from 'mdb-react-ui-kit';
import './WalletConnect.css';
import './AuthStyles.css';
import glass from '../assets/MagnifyingGlass.png';
import CaretDown from '../assets/CaretDown.png';
import PushPinSimple from '../assets/PushPinSimple.png';
import ChatCircleDots from '../assets/ChatCircleDots.png';
import sol from '../assets/sol.svg';
import eth from '../assets/eth.svg';
import mantle from '../assets/mantle.png';
import monad from '../assets/monad.jpg';
import btc from '../assets/btc.png';
import polygon from '../assets/polygon.png';
import hideIcon from '../assets/eye.webp';
import favIcon from '../assets/favorite.webp';
import favedIcon from '../assets/faved.webp';
import story from '../assets/story.png';
import chatIncoming from '../assets/chatIncoming.png';
import noti from '../assets/noti.png';
import { Circles } from 'react-loader-spinner';
require('dotenv').config();
const apiUrl = process.env.REACT_APP_API_URL || process.env.REACT_APP_API_URL_DEV || process.env.REACT_APP_API_URL_PREVIEW || '';
const WalletConnect = ({ isWrapped, setSelectedToken, socket }) => {
  const { publicKey, connected } = useWallet();
  const [tokens, setTokens] = useState([]);
  const [hiddenTokens, setHiddenTokens] = useState([]);
  const [activeTab, setActiveTab] = useState("Favorites");
  const [favorites, setFavorites] = useState([]); // New state for favorites
  const tokensRef = useRef(tokens);
  const [previousTokens, setPreviousTokens] = useState([]);
  const [latestMessages, setLatestMessages] = useState({});
  const [loading, setLoading] = useState(true);
  const [randomImage, setRandomImage] = useState(null);
  const tokenImages = {
    sol: sol,
    eth: eth,
    mantle: mantle,
    monad: monad,
    btc: btc,
    story: story,
    polygon: polygon,
  };
  const [newTokens, setNewTokens] = useState([]);

  const fetchUserFavorites = async () => {
    if (publicKey) {
      try {
        const response = await axios.get(`${apiUrl}/api/user/${publicKey}`);
        setFavorites(response.data.favorites || []);
      } catch (error) {
        console.error('Error fetching favorites:', error);
      }
    }
  };

  useEffect(() => {
    if (publicKey) fetchUserFavorites();
  }, [publicKey]);

  const toggleFavorite = async (tokenMint) => {
    const isFavorite = favorites.includes(tokenMint);

    try {
      const endpoint = isFavorite ? 'remove' : 'add';
      const response = await axios.post(`${apiUrl}/api/user/favorites/${endpoint}`, {
        publicKey: publicKey.toString(),
        tokenMint
      });
      setFavorites(response.data);
    } catch (error) {
      console.error(`Error toggling favorite:`, error);
    }
  };

  const fetchTokenMetadata = useCallback(async (tokensList) => {
    try {
      const metadataPromises = tokensList.map(async (token) => {
        try {
          const apiResponse = await axios.get(`${apiUrl}/api/token/${token.mint}`);
          const tokenInfo = apiResponse.data;

          return {
            ...token,
            name: tokenInfo.name || 'Unknown Token',
            symbol: tokenInfo.symbol || 'N/A',
            usdPrice: tokenInfo.usdPrice || 'N/A',
            marketCap: tokenInfo.marketCap || 'N/A',
            volume24: tokenInfo.volume24 || 'N/A',
            change24: tokenInfo.change24 || 'N/A',
            rawMarketCap: tokenInfo.rawMarketCap || 0,
            type: tokenInfo.type || 'Unknown',
            chain: tokenInfo.chain || 'Unknown',
            links: tokenInfo.links || {},
            logo: tokenInfo.logo || 'N/A',
          };
        } catch (error) {
          console.error(`Error fetching metadata for token ${token.mint} from backend:`, error);

          return {
            ...token,
            name: 'Unknown Token',
            symbol: 'N/A',
            usdPrice: 'N/A',
            marketCap: 'N/A',
            change24: 'N/A',
            volume24: 'N/A',
            type: 'Unknown',
            chain: 'N/A',
            rawMarketCap: 0,
            links: {},
            logo: 'N/A',
          };
        }
      });

      const tokensWithMetadata = await Promise.all(metadataPromises);

      // Sort tokens by rawMarketCap
      const sortedTokens = tokensWithMetadata.sort((a, b) => b.rawMarketCap - a.rawMarketCap);

      setTokens(sortedTokens); // Update tokens state with sorted tokens

    } catch (error) {
      console.error('Error fetching token metadata:', error);
    }
  }, []);


  const hideToken = (tokenMint) => {
    setHiddenTokens((prev) => [...prev, tokenMint]);
  };


  useEffect(() => {
    tokensRef.current = tokens;
  }, [tokens]);

  const handleNewToken = useCallback(async (data) => {
    console.log('New token received in WalletConnect:', data);
    const { tokenMint, symbol } = data;

    // Check if the token already exists
    const tokenExists = tokensRef.current.some(token => token.mint === tokenMint);
    if (!tokenExists) {
      // Fetch token metadata
      let tokenMeta;
      try {
        const apiResponse = await axios.get(`${apiUrl}/api/token/${tokenMint}`);
        tokenMeta = apiResponse.data;
      } catch (error) {
        console.error(`Error fetching metadata for token ${tokenMint}:`, error);
        tokenMeta = {
          mint: tokenMint,
          name: 'Unknown Token',
          symbol: symbol || 'N/A',
          usdPrice: 'N/A',
          marketCap: 'N/A',
          change24: 'N/A',
          volume24: 'N/A',
          type: 'Unknown',
          rawMarketCap: 0,
          links: {},
          logo: 'N/A',
        };
      }

      // Create the new token object
      const newToken = {
        mint: tokenMint,
        amount: 0,
        ...tokenMeta,
      };

      // Update tokens state
      setTokens(prevTokens => {
        const updatedTokens = [...prevTokens, newToken];
        // Sort tokens by rawMarketCap
        return updatedTokens.sort((a, b) => b.rawMarketCap - a.rawMarketCap);
      });

      // Update latestMessages state
      setLatestMessages(prevLatestMessages => ({
        ...prevLatestMessages,
        [tokenMint]: null,
      }));

      // Mark the token as new
      setNewTokens(prevNewTokens => [...prevNewTokens, tokenMint]);
    }
  }, [apiUrl]);

  useEffect(() => {
    if (!socket) return;

    socket.on('newToken', handleNewToken);

    return () => {
      socket.off('newToken', handleNewToken);
    };
  }, [socket]);



  const loginWithWallet = useCallback(async () => {
    if (publicKey) {
      try {
        await axios.post(`${apiUrl}/api/login`, { publicKey: publicKey.toString() }, { withCredentials: true });
      } catch (error) {
        console.error('Error logging in with wallet:', error);
      }
    }

  }, [publicKey]);


  useEffect(() => {
    if (publicKey) {
      loginWithWallet();
    }

  }, [publicKey, loginWithWallet]);


  const fetchTokens = useCallback(async () => {
    if (connected && publicKey) {
      setLoading(true);
      try {
        const response = await axios.post(`${apiUrl}/api/tokens`, { publicKey: publicKey.toString() });
        const currentTokens = response.data.map(token => ({
          mint: token.mint,
          amount: token.amount,
        }));

        // Fetch metadata for these tokens
        await fetchTokenMetadata(currentTokens);

      } catch (error) {
        console.error('Error fetching tokens:', error);
      }
      setLoading(false);
    }
  }, [connected, publicKey, fetchTokenMetadata]);



    const fetchLatestMessages = useCallback(async (tokens) => {
      try {
        const latestMessagesPromises = tokens.map(async (token) => {
          try {
            const apiResponse = await axios.get(`${apiUrl}/api/chatroom/${token.mint}/latest-message`);
            const latestMessage = apiResponse.data;
            console.log(`Latest message: ${latestMessage}`)
            return { mint: token.mint, latestMessage };
          } catch (error) {
            if (error.response && error.response.status === 404) {
              return { mint: token.mint, latestMessage: null };
            } else {
              console.error(`Error fetching latest message for ${token.mint}:`, error);
              return { mint: token.mint, latestMessage: null };
            }
          }
        });

        const latestMessagesArray = await Promise.all(latestMessagesPromises);

        const latestMessagesObj = latestMessagesArray.reduce((acc, { mint, latestMessage }) => {
          acc[mint] = latestMessage;
          return acc;
        }, {});

        setLatestMessages(latestMessagesObj);
      } catch (error) {
        console.error('Error fetching latest messages:', error);
      }

    }, []);


    useEffect(() => {
      if (connected && publicKey) {
        fetchTokens();
      }
    }, [connected, publicKey, fetchTokens]);


    const formatPrice = (price) => {
      if (price === 0) return '0.0';
      const log10 = Math.floor(Math.log10(price));
      const divisor = Math.pow(10, log10);
      return (Math.round(price / divisor * 10) / 10 * divisor).toString();
    };

    const CaretDownIcon = useMemo(() =>  (
      <svg viewBox="0 0 1024 1024" focusable="false" data-icon="caret-down" width="1em" height="1em" fill="currentColor" aria-hidden="true">
        <path d="M840.4 300H183.6c-19.7 0-30.7 20.8-18.5 35l328.4 380.8c9.4 10.9 27.5 10.9 37 0L858.9 335c12.2-14.2 1.2-35-18.5-35z"></path>
      </svg>
    ), []);

    const CaretUpIcon = useMemo(() => (
      <svg viewBox="0 0 1024 1024" focusable="false" data-icon="caret-up" width="1em" height="1em" fill="currentColor" aria-hidden="true">
        <path d="M183.6 724h656.8c19.7 0 30.7-20.8 18.5-35L530.5 308.2c-9.4-10.9-27.5-10.9-37 0L162.1 689c-12.2 14.2-1.2 35 18.5 35z"></path>
      </svg>
    ), []);;

    useEffect(() => {
      console.log("Active Tab:", activeTab);
    }, [activeTab]);

    const filteredTokens = useMemo(() => {
      let tabFiltered = tokens;

      if (activeTab === "Favorites") {
        tabFiltered = tokens.filter((token) => favorites.includes(token.mint));
      } else {
        tabFiltered = tokens.filter(token => token.type === activeTab);
      }
      return tabFiltered.filter((token) => !hiddenTokens.includes(token.mint));
    }, [tokens, activeTab, hiddenTokens, favorites]);



    const getChangeColor = (change) => {
      if (!change || change === 'N/A') return 'white';

      const changeNumber = parseFloat(change.replace('%', ''));
      return changeNumber < 0 ? '#9f110f' : '#22721C';
    };

    const formatChange24 = (change) => {
      if (!change || change === 'N/A') return 'N/A';

      const changeNumber = parseFloat(change.replace('%', ''));
      const absChange = Math.abs(changeNumber).toFixed(1); // Keep two decimal places

      return (
        <>
          {changeNumber < 0 ? CaretDownIcon : CaretUpIcon}
          {absChange}%
        </>
      );
    };

    const formatSimpleTimeDistance = (date) => {
      const seconds = Math.floor((new Date() - date) / 1000);
      const intervals = {
        year: 31536000,
        month: 2592000,
        week: 604800,
        day: 86400,
        hour: 3600,
        minute: 60,
      };

      for (const [key, value] of Object.entries(intervals)) {
        const count = Math.floor(seconds / value);
        if (count >= 1) {
          if (key === 'minute') return `${count} min ago`;
          if (key === 'hour') return `${count} h ago`;
          if (key === 'day') return `${count} d ago`;
          if (key === 'week') return `${count} week ago`;
          if (key === 'month') return `${count} month ago`;
          if (key === 'year') return `${count} year${count > 1 ? 's' : ''} ago`;
        }
      }
      return 'just now';
    };

    return (

        <MDBCard className="tokensCol">
          <MDBCardBody>
          {loading ? (
              <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
                <Circles height="80" width="80" color="white" ariaLabel="loading" />
              </div>
            ) : connected ? (
            <MDBTypography listUnStyled className="mb-0 list-tokens">
            <div className="initialChats">
              <div className="tokenColMenu">
                <a className={`item ${activeTab === "Favorites" ? "active" : ""}`} onClick={() => setActiveTab("Favorites")}>Favorites</a>
                <a className={`item ${activeTab === "PF Tokens" ? "active" : ""}`} onClick={() => setActiveTab("PF Tokens")}>Pump Fun Tokens</a>
                <a className={`item ${activeTab === "Other Tokens" ? "active" : ""}`} onClick={() => setActiveTab("Other Tokens")}>Other Tokens</a>
                <a className={`item ${activeTab === "NFTs" ? "active" : ""}`} onClick={() => setActiveTab("NFTs")}>NFTs</a>
                <a className={`item ${activeTab === "Ordinals" ? "active" : ""}`} onClick={() => setActiveTab("Ordinals")}>Ordinals</a>
                <a className={`item ${activeTab === "Runes" ? "active" : ""}`} onClick={() => setActiveTab("Runes")}>Runes</a>
                <a className={`item ${activeTab === "IP Assets" ? "active" : ""}`} onClick={() => setActiveTab("IP Assets")}>IP Assets</a>
                <a className={`item ${activeTab === "Prediction Markets" ? "active" : ""}`} onClick={() => setActiveTab("Prediction Markets")}>Prediction Markets</a>
                <a className={`item ${activeTab === "RWA Assets" ? "active" : ""}`} onClick={() => setActiveTab("RWA Assets")}>RWA Assets</a>
              </div>

              <div className="pinnedChats">
                <a className="item"><img src={PushPinSimple} className="pushPin" alt="PushPinSimple" style={{ width: '18px', height: '18px' }} /> Pinned Chats</a>
                <a className="item mostRecent">Most recent <img src={CaretDown} className="caretDown" alt="CaretDown" style={{ width: '18px', height: '18px' }} /></a>
              </div>
                
                  {filteredTokens.slice(0, 3).map((token, index) => (
                    <React.Fragment key={index}>
                      <li
                        key={index}
                        className={`p-2 tokenCard position-relative ${newTokens.includes(token.mint) ? 'new-token' : ''}`}
                        onClick={() => { 
                          setSelectedToken(token); 
                          // Optionally remove the new status
                          setNewTokens(prevNewTokens => prevNewTokens.filter(mint => mint !== token.mint));
                        }}
                      >
                      <div className="tokenImage">
                        <img src={ token.logo || 'https://via.placeholder.com/60' } alt="token-logo" className="tokenLogo position-relative"/>
                        {newTokens.includes(token.mint) && (
                          <span className="new-token-icon">New</span>
                        )}
                        {index === 0 && ( 
                          <img src={chatIncoming} alt="chatinc" className="chatIncoming"/>
                          )}
                        {index === 1 && ( 
                          <img src={noti} alt="noti" className="noti"/>
                          )}
                        {index === 3 && ( 
                          <img src={noti} alt="noti" className="noti"/>
                          )}
                      </div>
                      <a href="#!" className="link-light tokenCardContent">
                      {isWrapped ? (
                          <div className="d-flex flex-row align-items-center">
                              <p className="fw-bold mb-0 text-white tokenSymbol">{token.symbol || 'Unknown Token'}</p>
                          </div>
                      ) : (
                        <>
                            <div className="tokenTitle">
                            <p className="mb-0 tokenSymbol">
                              {["Pump Fun Coins", "Other Coins"].includes(token.type) ? `$${token.symbol || 'Unknown Token'}` : token.symbol || 'Unknown Token'}
                            </p>
                              <img src={tokenImages[token.chain]} alt="glass" className="chainLogo" />
                            </div>
                            <div className="tokenInfo">
                              <div className="tokenMetrics">
                                <p className="tokenMetricInfo">MC:${token.marketCap || 'N/A'}</p>
                                <p className="d-flex flex-row tokenMetricInfo">
                                  24h:
                                  <p style={{ color: getChangeColor(token.change24) }}>
                                    {formatChange24(token.change24 || 'N/A')}
                                  </p>
                                </p>
                                <p className="tokenMetricInfo">Vol:${token.volume24 || 'N/A'}</p>
                              </div>
                              {index === 0 ? (
                                <p className="small latestMessage firstOne">
                                  {latestMessages[token.mint] ? (
                                    <>
                                      <p className="isTyping">
                                        {latestMessages[token.mint].user.slice(0, 4)}...{latestMessages[token.mint].user.slice(-4)} is typing...
                                      </p>
                                      <p className="separator">
                                        ·
                                      </p>
                                      <p className="timestamp">
                                        Now
                                      </p>
                                    </>
                                  ) : (
                                    <p>
                                    </p>
                                  )}
                                </p>
                                ) : (
                                <p className="small latestMessage">
                                  {latestMessages[token.mint] ? (
                                    <>
                                      <p>
                                        {latestMessages[token.mint].user.slice(0, 4)}...{latestMessages[token.mint].user.slice(-4)}:
                                      </p>
                                      <p>
                                      {latestMessages[token.mint].message.startsWith('<img src=') ? (
                                        'Sent a GIF'
                                      ) : (
              
                                        latestMessages[token.mint].message.length > 10 
                                          ? `${latestMessages[token.mint].message.slice(0, 10)}...`
                                          : latestMessages[token.mint].message
                                      )}
                                      </p>
                                      <p className="separator">
                                        ·
                                      </p>
                                      <p className="timestamp">
                                        {latestMessages[token.mint].timestamp ? formatSimpleTimeDistance(new Date(latestMessages[token.mint].timestamp), { addSuffix: false }) : ''}
                                      </p>
                                    </>
                                  ) : (
                                    <p>
                                    </p>
                                  )}
                                </p>
                              )}
                          </div>
                          <a href="#!" className="d-flex justify-content-between link-light" onClick={() => { setSelectedToken(token) }}>
                            <div className="d-flex flex-row" style={{ width: '100%' }}>
                              <div className="d-flex m-auto flex-column justify-content-between">

                              </div>
                            </div>
                          </a>
                        </>
                      )}
                      </a>
                      <span onClick={(e) => { e.stopPropagation(); toggleFavorite(token.mint); }} className="favBtn">
                        <img src={favorites.includes(token.mint) ? favedIcon : favIcon} alt="Favorite" className="favIcon" />
                      </span>                      
                      <span onClick={(e) => { e.stopPropagation(); hideToken(token.mint); }} className="hideBtn">
                        <img src={hideIcon} alt="glass" className="hideIcon" />
                      </span>
                    </li>
                    {index === 2 && (
                      <div className="pinnedChats">
                        <a className="item"><img src={ChatCircleDots} className="pushPin" alt="PushPinSimple" style={{ width: '18px', height: '18px' }} />Chats</a>
                        <a className="item mostRecent">Most recent <img src={CaretDown} className="caretDown" alt="CaretDown" style={{ width: '18px', height: '18px' }} /></a>
                      </div>
                    )}
                    </React.Fragment>
                  ))}
              </div>
              <div className="otherChats">
                {filteredTokens.slice(3).map((token, index) => (
                  <React.Fragment key={index + 3}>
                  <li
                    key={index}
                    className={`p-2 tokenCard position-relative ${newTokens.includes(token.mint) ? 'new-token' : ''}`}
                    onClick={() => { 
                      setSelectedToken(token); 
                      // Optionally remove the new status
                      setNewTokens(prevNewTokens => prevNewTokens.filter(mint => mint !== token.mint));
                    }}
                  >
                    <div className="tokenImage">
                      <img src={ token.logo || 'https://via.placeholder.com/60' } alt="token-logo" className="tokenLogo position-relative"/>
                      {newTokens.includes(token.mint) && (
                        <span className="new-token-icon">New</span>
                      )}
                      {index === 0 && ( 
                        <img src={chatIncoming} alt="chatinc" className="chatIncoming"/>
                        )}
                      {index === 1 && ( 
                        <img src={noti} alt="noti" className="noti"/>
                        )}
                      {index === 3 && ( 
                        <img src={noti} alt="noti" className="noti"/>
                        )}
                    </div>
                    <a href="#!" className="link-light tokenCardContent" onClick={() => { setSelectedToken(token) }}>
                    {isWrapped ? (
                        <div className="d-flex flex-row align-items-center">
                            <p className="mb-0 tokenSymbol">
                              {["Pump Fun Coins", "Other Coins"].includes(token.type) ? `$${token.symbol || 'Unknown Token'}` : token.symbol || 'Unknown Token'}
                            </p>

                        </div>
                    ) : (
                      <>
                          <div className="tokenTitle">
                          <img src={tokenImages[token.chain]} alt="glass" className="chainLogo" />
                            <p className="mb-0 tokenSymbol">
                              {["Pump Fun Coins", "Other Coins"].includes(token.type) ? `$${token.symbol || 'Unknown Token'}` : token.symbol || 'Unknown Token'}
                            </p>
                          </div>
                          <div className="tokenInfo">
                            <div className="tokenMetrics">
                              <p className="tokenMetricInfo">MC:${token.marketCap || 'N/A'}</p>
                              <p className="d-flex flex-row tokenMetricInfo">
                                24h:
                                <p style={{ color: getChangeColor(token.change24) }}>
                                  {formatChange24(token.change24 || 'N/A')}
                                </p>
                              </p>
                              <p className="tokenMetricInfo">Vol:${token.volume24 || 'N/A'}</p>
                            </div>
                            {index === 0 ? (
                              <p className="small latestMessage firstOne">
                                {latestMessages[token.mint] ? (
                                  <>
                                    <p className="isTyping">
                                      {latestMessages[token.mint].user.slice(0, 4)}...{latestMessages[token.mint].user.slice(-4)} is typing...
                                    </p>
                                    <p className="separator">
                                      ·
                                    </p>
                                    <p className="timestamp">
                                      Now
                                    </p>
                                  </>
                                ) : (
                                  <p>
                                  </p>
                                )}
                              </p>
                              ) : (
                              <p className="small latestMessage">
                                {latestMessages[token.mint] ? (
                                  <>
                                    <p>
                                      {latestMessages[token.mint].user.slice(0, 4)}...{latestMessages[token.mint].user.slice(-4)}:
                                    </p>
                                    <p>
                                    {latestMessages[token.mint].message.startsWith('<img src=') ? (
                                      'Sent a GIF'
                                    ) : (
                  
                                      latestMessages[token.mint].message.length > 10 
                                        ? `${latestMessages[token.mint].message.slice(0, 10)}...`
                                        : latestMessages[token.mint].message
                                    )}
                                    </p>
                                    <p className="separator">
                                      ·
                                    </p>
                                    <p className="timestamp">
                                      {latestMessages[token.mint].timestamp ? formatSimpleTimeDistance(new Date(latestMessages[token.mint].timestamp), { addSuffix: false }) : ''}
                                    </p>
                                  </>
                                ) : (
                                  <p>
                                  </p>
                                )}
                              </p>
                            )}
                        </div>
                        <a href="#!" className="d-flex justify-content-between link-light" onClick={() => { setSelectedToken(token) }}>
                          <div className="d-flex flex-row" style={{ width: '100%' }}>
                            <div className="d-flex m-auto flex-column justify-content-between">

                            </div>
                          </div>
                        </a>
                      </>
                    )}
                    </a>
                    <span onClick={(e) => { e.stopPropagation(); toggleFavorite(token.mint); }} className="favBtn">
                      <img src={favorites.includes(token.mint) ? favedIcon : favIcon} alt="Favorite" className="favIcon" />
                    </span>                    
                    <span onClick={(e) => { e.stopPropagation(); hideToken(token.mint); }} className="hideBtn">
                      <img src={hideIcon} alt="glass" className="hideIcon" />
                    </span>
                  </li>
                  </React.Fragment>
                ))}
              </div>

            </MDBTypography>
            ) : (
              <div>
                <p className="text-white">Please connect your wallet to see your tokens.</p>
                <WalletMultiButton />
              </div>
            )}
          </MDBCardBody>
        </MDBCard>
    );
  };

export default WalletConnect;
