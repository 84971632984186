import React, { useEffect, useState, useMemo } from "react";
import Particles, { initParticlesEngine } from "@tsparticles/react";
import { loadSlim } from "@tsparticles/slim";
import Helmet from "react-helmet";
import xIcon from '../assets/xIcon.webp';
import monadLogo from '../assets/monad_logo.png';
import backgroundImage from '../assets/bg.jpeg';
import "./PasswordProtect.css";
import "./Madness.css";

const ParticleBackground = React.memo(() => {
  const options = useMemo(
    () => ({
      background: { color: { value: "transparent" } },
      fpsLimit: 120,
      interactivity: {
        events: { onClick: { enable: true, mode: "push" }, onHover: { enable: true, mode: "repulse" } },
        modes: { push: { quantity: 4 }, repulse: { distance: 200, duration: 0.4 } }
      },
      particles: {
        color: { value: "#70ed7c" },
        links: { color: "#70ed7c", distance: 150, enable: true, opacity: 0.5, width: 1 },
        move: { enable: true, speed: 2, outModes: { default: "bounce" } },
        number: { density: { enable: true }, value: 80 },
        opacity: { value: 0.5 },
        shape: { type: "circle" },
        size: { value: { min: 1, max: 5 } }
      },
      detectRetina: true,
    }),
    []
  );

  useEffect(() => {
    initParticlesEngine(async (engine) => {
      await loadSlim(engine);
    });
  }, []);

  return <Particles id="tsparticles" options={options} className="desktop-only" />;
});

const Madness = () => {
  return (
    <div className="madness-container">
      <ParticleBackground />
      <div className="madness-content">
        <div className="madness-header">
          <h1>Madness Begins.</h1>
        </div>
        <div className="waitlist-container">
        <div id="getWaitlistContainer" data-waitlist_id="22262" data-widget_type="WIDGET_1"></div>
          <Helmet>
            <link rel="stylesheet" type="text/css" href="https://prod-waitlist-widget.s3.us-east-2.amazonaws.com/getwaitlist.min.css"/>
            <script src="https://prod-waitlist-widget.s3.us-east-2.amazonaws.com/getwaitlist.min.js"></script>
          </Helmet>
        </div>
        <div className="twitter-follow">
          <p>🚨 Confidential link. Please don't share. 🚨</p>
          <p>Stay tuned for updates.</p>
          <a href="https://x.com/dusted_app" target="_blank" rel="noopener noreferrer">
            <img src={xIcon} alt="Twitter" className="twitter-icon" />
          </a>

        </div>
      </div>
      <div className="monad-logo">
        <img src={monadLogo} alt="Monad Logo" />
      </div>
    </div>
    
  );
};


export default Madness;