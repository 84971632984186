import React, { useState } from 'react';
import { GiphyFetch } from '@giphy/js-fetch-api';
import { Grid } from '@giphy/react-components';

const gf = new GiphyFetch('VOKTtcEPtLgD4BtKRgXtKtvoYq8oihv8'); // Replace with your Giphy API key

const GifSelector = ({ onGifSelect }) => {
  const [searchTerm, setSearchTerm] = useState('');

  const fetchGifs = (offset) => {
    return gf.search(searchTerm, { offset, limit: 10 });
  };

  return (
    <div>
      <input
        type="text"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        placeholder="Search for GIFs"
        style={{ width: '100%', padding: '10px', marginBottom: '10px' }}
      />
      <Grid
        key={searchTerm}
        fetchGifs={fetchGifs}
        width={500}
        columns={2}
        gutter={6}
        onGifClick={(gif, e) => {
          e.preventDefault();
          onGifSelect(gif.images.fixed_height.url);
        }}
      />
    </div>
  );
};

export default GifSelector;

